<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr class="form-control" v-model="date" placeholder="Select Date" :config="{
                            dateFormat: 'd/m/Y',
                            mode: 'range',
                            maxDate: new Date(),
                          }" style="background-color: transparent" @input="getdata($event)" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-form-group class="resButtons mt-2">
                      <div class="d-flex align-items-center justify-content-end">
                        <b-button v-b-tooltip.hover.v-success title="Print" variant="gradient-success"
                          class="ml-2 btn-icon" @click="printData">
                          <feather-icon icon="PrinterIcon" />
                        </b-button>
                        <b-button v-b-tooltip.hover.v-success title="Excel" variant="gradient-success"
                          class="ml-2 btn-icon" @click="clickExcel">
                          <feather-icon icon="FileTextIcon" />
                        </b-button>
                      </div>
                    </b-form-group>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="getPandl">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
                <div id="pandl">
                  <div class="overflow-auto">
                    <div class="pandl-table">
                      <TreeTable :value="finalData" class="w-100">
                        <Column field="name" header="Name" :expander="true"></Column>
                        <Column field="amount" header="Amount" bodyClass="text-right" headerClass="text-right"></Column>
                      </TreeTable>
                    </div>
                  </div>
                </div>
              </b-card-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import ReportTable from "../../../components/ReportTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import children from "./balancesheetChild.vue";

import TreeTable from "primevue/treetable";
import Column from "primevue/column";
import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  VBTooltip,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    TreeTable,
    Column,
    children,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable,
  },
  data() {
    return {
      incomeExpenseData: "",
      startdate: "",
      enddate: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      data1: [],
      fields: [
        { field: "ledger", label: "Ledger" },
        { field: "amount", label: "Amount" },
      ],
      expanded: {},
      ledgerExpanded: {},
      expenseObj: {},
      incomeObj: {},
      expense: [],
      income: [],
      cogs: [],
      dublicateData: [],

      showHideData: {},
      expenseData: [],
      cogsData: [],
      incomeData: [],
      cogsTemp: [],
      finalData: [],
      date: "",
    };
  },
  mounted() {
    this.date =
      moment().startOf("month").format("DD/MM/YYYY") +
      " to " +
      moment().endOf("month").format("DD/MM/YYYY");
    this.startdate = moment().startOf("month").format("DD/MM/YYYY");
    this.enddate = moment().endOf("month").format("DD/MM/YYYY");
    this.getPandl();
  },
  methods: {
    // handelclickexpense(id) {
    //   this.showHideData[id] =
    //     this.showHideData[id] == undefined ? false : this.showHideData[id];
    //   this.showHideData[id] = this.showHideData[id] == true ? false : true;
    //   this.$forceUpdate();
    // },

    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },

    clickExcel() {
      var aoa = [
        ["Balance Sheet"],
        [],
        [],
      ];
      let arr = this.setExcel();
      aoa = [...aoa, ...arr];

      var ws = XLSX.utils.aoa_to_sheet(aoa);
      var html_string = XLSX.utils.sheet_to_html(ws, {
        id: "data-table",
        editable: true,
      });
      document.getElementById("container").innerHTML = html_string;
      function doit(type, fn) {
        var elt = document.getElementById("data-table");
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        XLSX.writeFile(wb, fn || "SheetJSTableExport." + (type || "xlsx"));
      }
      doit("xlsx", "Balance sheet" + ".xlsx");
    },
    setExcel() {
      let obj = {};
      obj[""] = { name: "", data: [] };
      obj["Ledgers"] = { name: "Ledgers", data: [] };
      this.setChildExcel(obj, { children: this.finalData });

      let arr = [];
      for (let key in obj) {
        arr.push([obj[key].name, ...obj[key].data]);
        if (obj[key].total) {
          arr.push([])
        }
      }

      return arr;
    },
    setChildExcel(obj, child) {
      child.children &&
        child.children.length > 0 &&
        child.children.map((item) => {
          let name = item.data.name;

          obj[`${item.data.name}-${item.children?.length}`] = { name, data: [] };
          if (item.children && item.children.length > 0) {
            obj[`${item.data.name}-${item.children?.length}`].data.push("");
          } else {
            obj[`${item.data.name}-${item.children?.length}`].data.push(
              this.roundOfDecimal(item.data.amount ?? 0, 2)
            );
          }

          this.setChildExcel(obj, item);

          if (
            item.children &&
            item.children.length > 0
          ) {
            obj[`${item.data.name}-${item.children?.length}-total`] = {
              name: `Total ${name}`,
              data: [],
              total: true
            };

            obj[`${item.data.name}-${item.children?.length}-total`].data.push(
              this.roundOfDecimal(item.data.amount ?? 0, 2)
            );
          }
        });
    },
    printData() {
      const divToPrint = document.getElementById("pandl");
      const newWin = window.open("", "_blank");

      const cssLinks = Array.from(
        document.querySelectorAll("link[rel='stylesheet'], style")
      );

      // Write the content and head (including stylesheets) to the new window
      newWin.document.write(`
          <html>
            <head>
              <title>Print Preview</title>
              ${cssLinks.map((link) => link.outerHTML).join("\n")}
              <style>

                  button {
                    visibility: hidden !important;
                  }
                  td {
                    border: 1px solid #e9ecef;
                  }
                  h3,
                  h5 {
                    color: black;
                  }
                  .text-right{
                    text-align:right
                  }
              </style>
            </head>
            <body>
              <div style='text-align:center'>
              <h3 style='margin-bottom:0px'>Balance Sheet</h3>
              <h5>${this.enddate}</h5>
              </div>
              ${divToPrint.outerHTML}
            </body>
          </html>
        `);
      newWin.document.close();

      // Trigger the print dialog
      newWin.focus();
      newWin.print();
    },
    async getPandl() {
      let data = {
        startdate: moment(this.startdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        enddate: moment(this.enddate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };

      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/profitloss`,
      })
        .then(async (res) => {
          this.finalData = [];
          // this.updateShowHideData("Assets", true);
          // this.updateShowHideData("Liability", true);
          let res_group = res.data.data;
          let groups = (res_group[0].child_groups
            ? res_group[0].child_groups
            : []
          ).concat(res_group[0].child_ledgers ? res_group[0].child_ledgers : []);

          groups.forEach((ele, index) => {
            let childrens = (ele.child_groups ? ele.child_groups : []).concat(
              ele.child_ledgers ? ele.child_ledgers : []
            );
            let children1 = [];
            childrens.map((item, index) => {
              let tempData = {};
              tempData = this.recorsionFun(item, "Income");
              item = tempData;
              // item.amount = this.roundOfDecimal(item.amount, 2);
              children1[index] = item;
            });
            let ele1 = {
              key: ele.role == "ledger" ? "ledger-" + ele.id : ele.id,
              data: {
                name: ele.name,
                amount: this.roundOfDecimal(ele.amount, 2),
              },
              children: children1,
            };
            groups[index] = ele1;
          });
          this.finalData.push({
            children: groups,
            data: {
              name: "Income",
              key: "Income",
              amount: this.roundOfDecimal(res_group[0].amount),
            },
          });

          let groups1 = (res_group[1].child_groups
            ? res_group[1].child_groups
            : []
          ).concat(res_group[1].child_ledgers ? res_group[1].child_ledgers : []);
          groups1.forEach((ele, index) => {
            let childrens = (ele.child_groups ? ele.child_groups : []).concat(
              ele.child_ledgers ? ele.child_ledgers : []
            );
            let children1 = [];

            childrens.map((item, index) => {
              let tempData = {};
              tempData = this.recorsionFun(item, "Cost of good sold");
              children1[index] = tempData;
            });

            let ele1 = {
              key: ele.role == "ledger" ? "ledger-" + ele.id : ele.id,
              data: {
                name: ele.name,
                amount: this.roundOfDecimal(ele.amount, 2),
              },
              children: children1,
            };
            groups1[index] = ele1;
          });
          this.finalData.push({
            children: groups1,
            key: "Cost of good sold",
            data: {
              name: "Cost of good sold",
              amount: this.roundOfDecimal(res_group[1].amount),
            },
          });
          this.finalData.push({
            key: "Gross Profit",
            data: {
              name: "Gross Profit",
              amount: this.roundOfDecimal(res_group[0].amount + res_group[1].amount),
            },
          });
          let groups2 = (res_group[2].child_groups
            ? res_group[2].child_groups
            : []
          ).concat(res_group[2].child_ledgers ? res_group[2].child_ledgers : []);

          groups2.forEach((ele, index) => {
            let childrens = (ele.child_groups ? ele.child_groups : []).concat(
              ele.child_ledgers ? ele.child_ledgers : []
            );
            let children1 = [];

            childrens.map((item, index) => {
              let tempData = {};
              tempData = this.recorsionFun(item, "Expense");
              children1[index] = tempData;
            });

            let ele1 = {
              key: ele.role == "ledger" ? "ledger-" + ele.id : ele.id,
              data: {
                name: ele.name,
                amount: this.roundOfDecimal(ele.amount * -1, 2),
              },
              children: children1,
            };
            groups2[index] = ele1;
          });
          console.log(groups2);

          this.finalData.push({
            children: groups2,
            key: "Expense",
            data: {
              name: "Expense",
              amount: this.roundOfDecimal(res_group[2].amount * -1),
            },
          });
          this.finalData.push({
            key: "Net Profit",
            data: {
              name: "Net Profit",
              amount: this.roundOfDecimal(
                res_group[0].amount + res_group[1].amount + +res_group[2].amount
              ),
            },
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    recorsionFun(ele, name) {
      let children1 = [];
      let childrens = (ele.child_groups ? ele.child_groups : []).concat(
        ele.child_ledgers ? ele.child_ledgers : []
      );
      childrens.map((item, index) => {
        let tempData = {};
        tempData = this.recorsionFun(item, name);
        children1[index] = tempData;
      });
      let ele1 = {
        key: ele.role == "ledger" ? "ledger" + ele.id : ele.id,
        data: {
          name: ele.name,
          amount: ele.amount,
        },
        children: children1,
      };
      if (name == "Cost of good sold" || name == "Expense") {
        ele1.data.amount = ele1.data.amount * -1;
      }
      return ele1;
    },
  },
};
</script>

<style>
#pandl {
  overflow: auto;
  display: flex;
  justify-content: center;
}

.pandl-table {
  min-width: 400px;
  width: 100%;
  max-width: 650px;
}
</style>
